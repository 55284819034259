import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FacebookFilled, TwitterCircleFilled, InstagramFilled } from '@ant-design/icons';
import './MainLayout.less';

const { Footer } = Layout;

const {Title} = Typography;

const rootRoutes = ['/', '/about', '/contact'];
const aboutSubRoutes = ['/about/me', '/about/company'];

const styles = {
  icon: {
    fontSize: '3em',
  },
};

export default class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const { collapsed } = this.state;
    const { children } = this.props;
    return (
      <Layout>
        {children}
        <Footer>
          <Row type="flex" justify="space-between">
            <Col>
              <Row><Title level={4} style={{color: '#169966'}}>ميني ماركت التوفير</Title></Row>
              <Row><a href="/">الرئيسية</a></Row>
              <Row><a href="/products">البضائع</a></Row>
              <Row><a href="/contactus">تواصل معنا</a></Row>
            </Col>
            <Col>
              <Row><Title level={4} style={{color: '#169966'}}>مواقع التواصل الإجتماعي</Title></Row>
              <Row type="flex" justify="space-around">
                <Col><FacebookFilled style={styles.icon} /></Col>
                <Col><TwitterCircleFilled style={styles.icon} /></Col>
                <Col><InstagramFilled style={styles.icon} /></Col>
              </Row>
            </Col>
          </Row>
        </Footer>
      </Layout>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
