var api = require("!../../../../../../nix/store/mkda57vq2hrc1466hlwybm17y84j5ibp-node_fe-1.0.0/lib/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../../nix/store/mkda57vq2hrc1466hlwybm17y84j5ibp-node_fe-1.0.0/lib/node_modules/css-loader/dist/cjs.js!../../../../../../nix/store/mkda57vq2hrc1466hlwybm17y84j5ibp-node_fe-1.0.0/lib/node_modules/less-loader/dist/cjs.js??ref--7-2!./MainLayout.less");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};